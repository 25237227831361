<template>
  <post :post="data"></post>
</template>

<script>
import Post from '../components/Post'
import data from '../mock/ContactUs.json'
export default {
  name: 'Contact',
  components: { Post },
  data: function () {
    return {
      data: data
    }
  }
}
</script>

<style scoped>

</style>
